"use client";
/* Core */
import { createSlice } from "@reduxjs/toolkit";
import { activeGameDetail, buyEnergy, gameComplete, gameHistory, gameLeaderBoard, gameStart, updateGameMove } from "./actions";


const initialState = {
  loading: false,
  gameDetail: null,
  gameHistoryList: [],
  totalGameHistory: 0,
  gameLeaderboardList: [],
  totalGameLeaderboard: 0
};

export const gameSlice = createSlice({
  name: "gameSlice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(gameStart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(gameStart.fulfilled, (state, action) => {
        state.loading = false;
        state.gameDetail = action.payload.data
      })
      .addCase(gameStart.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(gameComplete.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(gameComplete.fulfilled, (state, action) => {
        // state.loading = false;
        state.gameDetail = action.payload.data
      })
      .addCase(gameComplete.rejected, (state, action) => {
        // state.loading = false;
      })
      .addCase(updateGameMove.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(updateGameMove.fulfilled, (state, action) => {
        // state.loading = false;
        state.gameDetail = action.payload.data
      })
      .addCase(updateGameMove.rejected, (state, action) => {
        // state.loading = false;
      })
      .addCase(activeGameDetail.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(activeGameDetail.fulfilled, (state, action) => {
        // state.loading = false;
        state.gameDetail = action.payload.data
      })
      .addCase(activeGameDetail.rejected, (state, action) => {
        // state.loading = false;
      })
      .addCase(buyEnergy.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(buyEnergy.fulfilled, (state, action) => {
        // state.loading = false;
      })
      .addCase(buyEnergy.rejected, (state, action) => {
        // state.loading = false;
      })
      .addCase(gameHistory.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(gameHistory.fulfilled, (state, action) => {
        // state.loading = false;
        state.gameHistoryList = action.payload.data
      })
      .addCase(gameHistory.rejected, (state, action) => {
        // state.loading = false;
      })
      .addCase(gameLeaderBoard.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(gameLeaderBoard.fulfilled, (state, action) => {
        // state.loading = false;
        state.gameLeaderboardList = action.payload.data
      })
      .addCase(gameLeaderBoard.rejected, (state, action) => {
        // state.loading = false;
      })
  },
});

export default gameSlice.reducer;