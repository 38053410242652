import React, { useState } from "react";
import { Button, Dropdown, Modal, Nav, Tab } from "react-bootstrap";
import styles from "./LeaderBoardPop.module.scss";

// img
import p1 from "../../../assets/images/current.png";
import diamond from "../../../assets/images/diamond.png";

const LeaderBoardPop = ({ leaderboard, setLeaderboard }) => {
  const handleLeaderBoard = () => {
    setLeaderboard(!leaderboard);
  };
  return (
    <>
      <Modal
        show={leaderboard}
        onHide={handleLeaderBoard}
        backdrop="true"
        scrollable={true}
        centered
        className={`${styles.HistoryPop} bottomPop`}
        keyboard={false}
      >
        <Modal.Body className="p-0 pt-2">
          {/* <Button
            onClick={handleLeaderBoard}
            variant="transparent"
            className="border-0 p-0 position-absolute"
            style={{ top: 10, left: 10 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 23"
              fill="none"
            >
              <path
                d="M3.55503 11.4995L13.8108 2.91879C14.0631 2.70776 14.0631 2.36931 13.8108 2.15828C13.5586 1.94724 13.1541 1.94724 12.9018 2.15828L2.18917 11.1212C1.93694 11.3323 1.93694 11.6707 2.18917 11.8818L12.9018 20.8407C13.0256 20.9443 13.1921 21 13.354 21C13.5158 21 13.6823 20.9482 13.8061 20.8407C14.0583 20.6297 14.0583 20.2912 13.8061 20.0802L3.55503 11.4995Z"
                fill="white"
                stroke="white"
                stroke-width="2.5"
              />
            </svg>
          </Button> */}
          <div
            className={`${styles.top} top pb-3 px-2 text-center d-flex align-items-center justify-content-between`}
          >
            <div
              className={`${styles.timer} text-white p-1 timer d-flex align-items-center`}
            >
              <span className="icn me-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.8996 9.33333C6.02016 9.33333 5.30738 8.7066 5.30738 7.93333C5.30738 7.32433 5.75214 6.81193 6.36886 6.6192V4.2C6.36886 3.9424 6.6061 3.73333 6.8996 3.73333C7.1931 3.73333 7.43034 3.9424 7.43034 4.2V6.6192C8.04705 6.81193 8.49181 7.32433 8.49181 7.93333C8.49181 8.7066 7.77903 9.33333 6.8996 9.33333ZM7.42502 1.88999L7.43034 0.933333H9.55329C9.84679 0.933333 10.084 0.724733 10.084 0.466667C10.084 0.2086 9.84679 0 9.55329 0H4.24591C3.95241 0 3.71517 0.2086 3.71517 0.466667C3.71517 0.724733 3.95241 0.933333 4.24591 0.933333H6.36886V1.86667L6.37417 1.88999C2.81026 2.12706 0 4.73853 0 7.93333C0 11.284 3.0889 14 6.8996 14C10.7103 14 13.7992 11.284 13.7992 7.93333C13.7992 4.73853 10.9889 2.12706 7.42502 1.88999Z"
                    fill="#54B3F5"
                  />
                </svg>
              </span>
              168:00:00
            </div>

            <p
              className="m-0 themeClr d-inline-block pb-1 px-3"
              style={{ borderBottom: "1px solid #356BBF" }}
            >
              Leaderboard
            </p>
            <div
              className={`${styles.tab} p-1 text-white px-2`}
              style={{ background: "#526DB8" }}
            >
              Last Week
            </div>
          </div>
          <div className="table-responsive">
            <table className="table text-center">
              <thead>
                <tr>
                  <th className="border-0 bg-transparent fw-normal">Place</th>
                  <th className="border-0 bg-transparent fw-normal">
                    User name
                  </th>
                  <th className="border-0 bg-transparent fw-normal">Points</th>
                  <th className="border-0 bg-transparent fw-normal">Prizes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">1. </h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">PlayerRr</h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">20205</h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">8 000</h6>
                  </td>
                </tr>
                <tr>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">2.</h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">PlayerRr</h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">20205</h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">8 000</h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeaderBoardPop;
