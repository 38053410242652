/* Instruments */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../helper/url_helper";
import { get, post, put, updateToken } from "../../../helper/api_helper";
import { toast } from "react-toastify";

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const gameStart = createAsyncThunk(
  "gameStart",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      console.log("URL.GAME_START", URL.GAME_START)
      const response = await post(URL.GAME_START, data);
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const gameComplete = createAsyncThunk(
  "gameComplete",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      const response = await put(URL.GAME_COMPLETE + data?._id, data);
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const updateGameMove = createAsyncThunk(
  "updateGameMove",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      const response = await put(URL.GAME_UPDATE + data?._id, data);
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const activeGameDetail = createAsyncThunk(
  "activeGameDetail",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      const response = await get(URL.GAME_DETAILS + data?._id);
      console.log("response", response)
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);



export const buyEnergy = createAsyncThunk(
  "buyEnergy",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      const response = await post(URL.PURCHASE_ENERGY, data);
      callBack && callBack(null, response);
      // store.dispatch(saveUserData(res?.data)) //important
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const gameHistory = createAsyncThunk(
  "gameHistory",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      const response = await post(URL.GAME_HISTORY_LIST, data);
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const gameLeaderBoard = createAsyncThunk(
  "gameLeaderBoard",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      const response = await post(URL.GAME_LEADERBOARD_LIST, data);
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

