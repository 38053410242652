import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import styles from "./game.module.scss";

// img
import current from "../../../assets/images/current.png";
import diamond from "../../../assets/images/diamond.png";
import HowToPlayPop from "../../../components/Modals/HowToPlay";
import BuyPop from "../../../components/Modals/BuyPop";
import GameHistoryPop from "../../../components/Modals/GameHistory";
import HistoryDetailPop from "../../../components/Modals/historyDetailPop";
import LeaderBoardPop from "../../../components/Modals/LeaderboardPop";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { playSound } from "../../../helper/common";
import { toast } from "react-toastify";
import { activeGameDetail, buyEnergy, gameComplete, gameStart, getSettings, getUserProfile, updateProfile, userSessionLogout } from "../../../lib/store/actions";

const Images = {

}

const gameArray = {
  "gameHistoryId": "65e5780457749e542ec97449",
  "lineCount": 0,
  "crystalsClaimed": 60,
  "nftsClaimed": 0,
  "roundResult": [
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": 5,
          "isOpened": false,
          "lineCount": 1,
        },
        {
          "column": 2,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 1,
        },
        {
          "column": 3,
          "value": 6,
          "isOpened": false,
          "lineCount": 1,
        },
        {
          "column": 4,
          "value": 2,
          "isOpened": false,
          "lineCount": 1,
        }
      ]
    },
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": 11,
          "isOpened": false,
          "lineCount": 2,
        },
        {
          "column": 2,
          "value": 16,
          "isOpened": false,
          "lineCount": 2,
        },
        {
          "column": 3,
          "value": 12,
          "isOpened": false,
          "lineCount": 2,
        },
        {
          "column": 4,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 2,
        }
      ]
    },
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 3,
        },
        {
          "column": 2,
          "value": 27,
          "isOpened": false,
          "lineCount": 3,
        },
        {
          "column": 3,
          "value": 22,
          "isOpened": false,
          "lineCount": 3,
        },
        {
          "column": 4,
          "value": 30,
          "isOpened": false,
          "lineCount": 3,
        }
      ]
    },
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 4,
        },
        {
          "column": 2,
          "value": 37,
          "isOpened": false,
          "lineCount": 4,
        },
        {
          "column": 3,
          "value": 33,
          "isOpened": false,
          "lineCount": 4,
        },
        {
          "column": 4,
          "value": 35,
          "isOpened": false,
          "lineCount": 4,
        }
      ]
    },
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": 41,
          "isOpened": false,
          "lineCount": 5,
        },
        {
          "column": 2,
          "value": 42,
          "isOpened": false,
          "lineCount": 5,
        },
        {
          "column": 3,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 5,
        },
        {
          "column": 4,
          "value": 48,
          "isOpened": false,
          "lineCount": 5,
        }
      ]
    },
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 6,
        },
        {
          "column": 2,
          "value": 55,
          "isOpened": false,
          "lineCount": 6,
        },
        {
          "column": 3,
          "value": 60,
          "isOpened": false,
          "lineCount": 6,
        },
        {
          "column": 4,
          "value": 60,
          "isOpened": false,
          "lineCount": 6,
        }
      ]
    },
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": 61,
          "isOpened": false,
          "lineCount": 7,
        },
        {
          "column": 2,
          "value": 61,
          "isOpened": false,
          "lineCount": 7,
        },
        {
          "column": 3,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 7,
        },
        {
          "column": 4,
          "value": 62,
          "isOpened": false,
          "lineCount": 7,
        }
      ]
    },
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": 73,
          "isOpened": false,
          "lineCount": 8,
        },
        {
          "column": 2,
          "value": 80,
          "isOpened": false,
          "lineCount": 8,
        },
        {
          "column": 3,
          "value": 72,
          "isOpened": false,
          "lineCount": 8,
        },
        {
          "column": 4,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 8,
        }
      ]
    },
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": 86,
          "isOpened": false,
          "lineCount": 9,
        },
        {
          "column": 2,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 9,
        },
        {
          "column": 3,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 9,
        },
        {
          "column": 4,
          "value": 82,
          "isOpened": false,
          "lineCount": 9,
        }
      ]
    },
    {
      "isClicked": false, "row": [
        {
          "column": 1,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 10,
        },
        {
          "column": 2,
          "value": "bomb",
          "isOpened": false,
          "lineCount": 10,
        },
        {
          "column": 3,
          "value": "nft",
          "isOpened": false,
          "lineCount": 10,
        },
        {
          "column": 4,
          "value": 97,
          "isOpened": false,
          "lineCount": 10,
        }
      ]
    }
  ]
}

const Game = () => {
  const initialData = [
    { id: 1, level: [true, false, true, true] },
    { id: 2, level: [false, true, true, false] },
    { id: 3, level: [true, true, false, false] },
    { id: 4, level: [false, false, true, true] },
    { id: 5, level: [true, true, true, false] },
    { id: 6, level: [false, false, false, true] },
    { id: 7, level: [true, true, false, true] },
    { id: 8, level: [false, true, false, true] },
    { id: 9, level: [true, false, true, true] },
    { id: 10, level: [false, true, false, false] },
  ];
  const navigate = useNavigate();
  const elementRef = useRef(null);
  const [data, setData] = useState(initialData);
  const [height, setHeight] = useState(0);
  const [playInst, setPlayInst] = useState();
  const [buy, setBuy] = useState();
  const [leaderboard, setLeaderboard] = useState();
  const [history, setHistory] = useState();
  const [historyDetail, setHistoryDetail] = useState();
  const { user: userData } = useSelector(({ Auth }) => Auth)
  const [energyModal, setEnergyModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [openHowToPlay, setHowToPlay] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [leaderBoardModal, setleaderBoardModal] = useState(false);
  const [game, SetGame] = useState(gameArray);
  const [refresh, setRefresh] = useState(false);
  const [claimDiamondCount, setClaimDiamondCount] = useState(0);
  const [isGameStart, setGameStart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSound, setisSound] = useState(false);
  const [isOpenPlan, setOpenPlan] = useState(false);
  const [gameEnergyLeft, setGameEnerguLeft] = useState(userData?.energyBalance);
  const [btncBalance, setBtncBalance] = useState(userData?.btncBalance);
  const { settings } = useSelector(({ Settings }) => Settings);
  console.log(height, "height");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings({}));
  }, []);

  useEffect(() => {
    // Check if the reference is set and access its height
    if (elementRef.current) {
      setHeight(elementRef.current.clientHeight);
    }
  }, []);

  const handlePlayInst = () => {
    setPlayInst(!playInst);
  };
  const handledBuy = () => {
    setBuy(!buy);
  };
  const handleLeaderBoard = () => {
    setLeaderboard(!leaderboard);
  };
  const handleHistory = () => {
    setHistory(!history);
  };

  const handleOptionClick = (value, list, id) => {
    console.log(value, id, list, "ritesh");
  };
  const handleGameStart = () => {
    // Modify the first row only
    // setData((prevData) =>
    //   prevData.map((item, index) =>
    //     index === 0 ? { ...item, level: [true, false, true, false] } : item
    //   )
    // );
    startGame();
  };

  //game functions 

  useEffect(() => {
    // if (!!userData) {
    setisSound(userData?.enabledSound);
    //}
  }, [])


  useEffect(() => {
    getProfile();

  }, [])


  const purchaseEnergy = (energyCount, paymentType) => {
    let bodyParm = {
      paymentMode: paymentType,
      quantity: energyCount
    }
    setIsLoading(true);
    dispatch(buyEnergy({
      data: bodyParm, callback: (err, res) => {
        if (err) return;
        playSound('energybuy')
        setEnergyModal(false)
        // showSuccess(res?.message)
      }
    }))

  }
  const setbalance = (balance) => {
    if (balance > 999999) { // handle to fix design issue
      let b = balance?.toString();
      return b?.substring(0, 7) + '...'
    } else {
      return balance?.toFixed(1);
    }

  }

  const ativeGameDetail = (currentGame) => {
    let bodyParms = {
      _id: currentGame || userData?.currentGame
    }
    setIsLoading(true)
    dispatch(activeGameDetail({
      data: bodyParms, callBack: (err, res) => {
        if (err) return;
        setIsLoading(false)
        SetGame({ ...res.data, lineCount: res.data.lineCount + 1, })
        setGameStart(true)
      }
    }));
  };

  const startGame = () => {
    let bodyParms = {
    }
    setIsLoading(true)
    dispatch(gameStart({
      data: bodyParms, callBack: (err, res) => {
        if (err) return;
        setIsLoading(false)
        setClaimDiamondCount(0)
        playSound('startround')
        setGameEnerguLeft(res?.otherData?.gameEnergy);
        setGameStart(true)
        SetGame({ ...res.data, lineCount: res.data.lineCount + 1, })
      }
    }))
  }

  const claimBtncs = (needToEnd) => {
    let bodyParms = game
    game.gameHistoryId = game?._id
    if (needToEnd == 2) {
      game.btncClaimed = 0 // bomb case set crystal to 0
    }
    setIsLoading(true)
    dispatch(gameComplete({
      data: bodyParms, callback: (err, res) => {
        setIsLoading(false)
        if (needToEnd == 2) {
          //playSound('')
        } else {
          playSound('claim')
        }
        for (var i = 0;i < game.roundResult.length;i++) {   // show the game history when end or claim 
          game.roundResult[i].isClicked = true
        }
        setGameEnerguLeft(res?.data?.gameEnergy) // manage energy used count
        setBtncBalance(res.data.btncBalance)
        SetGame(game);
        setRefresh(!refresh)
        setGameStart(false)    // reset to start button
        // showSuccess(res?.message)
      }
    }))
  }

  const updateGameMove = (needToEnd) => { // update every move on backend
    game.gameHistoryId = game?._id
    dispatch(updateGameMove({
      data: game, callBack: (err, res) => {
        if (err) {
          toast.error(err.message)
          // showError(res.message);
          for (var i = 0;i < game.roundResult.length;i++) {   // show the game history when end or claim 
            game.roundResult[i].isClicked = true
          }
          SetGame(game);
          setRefresh(!refresh)
          setGameStart(false)    // reset to start button
          getProfile();
        } else {
          if (needToEnd == 2) {
            claimBtncs(needToEnd)
          }
        }
      }
    }))
  }

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  }

  const getColor = (item, index) => {
    if (item.value == 'bomb' && game.roundResult[item.lineCount - 1].row[index].isOpened) {
      return '#D90E2F'
    } else if (game.roundResult[item.lineCount - 1].row[index].isOpened) {
      return '#1DECB2'
    } else {
      return '#4564A2'
    }
  }

  const getProfile = () => {
    let bodyParms = {
      _id: userData?._id
    }
    setIsLoading(true)
    dispatch(getUserProfile({
      data: bodyParms, callBack: (err, res) => {
        if (err) return;
        if (res.status === "success") {
          console.log("res?.data?.currentGame", res?.data)
          setGameEnerguLeft(res?.data?.energyBalance)
          if (res?.data?.currentGame) {
            ativeGameDetail(res?.data?.currentGame)
          } else {
            //store.dispatch(saveUserData(res?.data))
          }
        } else {
          console.log(res.message);
        }
      }
    }));
  }

  const renderData = ({ item, index }) => {
    const handleClick = () => {
      if (item.value === 'bomb') {
        playSound('bombendgame');
        game.roundResult[item.lineCount - 1].row[index].isOpened = true;
        for (let i = 0;i < game.roundResult.length;i++) {
          game.roundResult[i].isClicked = true;
        }
        claimBtncs(2);
        SetGame(game);
        setRefresh(!refresh);
        updateGameMove(1);
      } else if (item.value === 'nft') {
        playSound('shipwin');
        const claimNft = 1;
        game.roundResult[item.lineCount - 1].row[index].isOpened = true;
        game.roundResult[item.lineCount - 1].isClicked = true;
        game.nftsClaimed = claimNft;
        SetGame({ ...game, lineCount: game.lineCount + 1 });
        updateGameMove(1);
      } else if (item.value === 'usdt') {
        playSound('shipwin');
        game.roundResult[item.lineCount - 1].row[index].isOpened = true;
        game.roundResult[item.lineCount - 1].isClicked = true;
        game.usdtClaimed = item?.usdtValue;
        SetGame({ ...game, lineCount: game.lineCount + 1 });
        updateGameMove(1);
      } else {
        if (item?.lineCount % 2 === 0) {
          playSound('diamondevenline');
        } else {
          playSound('diamondoddline');
        }
        const claimDiamond = claimDiamondCount + (item?.value || 0);
        setClaimDiamondCount(claimDiamond);
        game.roundResult[item.lineCount - 1].row[index].isOpened = true;
        game.roundResult[item.lineCount - 1].isClicked = true;
        game.crystalsClaimed = claimDiamond;
        SetGame({ ...game, lineCount: game.lineCount + 1 });
        updateGameMove(1);
      }
    };

    return (
      <button
        disabled={item.lineCount === game.lineCount && !game.roundResult[item.lineCount - 1].isClicked ? false : true}
        onClick={handleClick}
        style={{
          padding: '1px',
          height: '6.15vh', // Convert responsiveScreenHeight to CSS
          width: `${100 / 5.2}vw`, // Convert Dimensions.get('window').width to CSS
          backgroundColor: item.lineCount === game.lineCount && !game.roundResult[item.lineCount - 1].isClicked ? '#41B4E6' : '#2F376D',
          borderRadius: '10px',
          borderColor: getColor(item, index),
          borderWidth: '1.7px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          borderStyle: 'solid',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ padding: '1px' }}>
          <div
            style={{
              height: '6.15vh', // Convert responsiveScreenHeight to CSS
              width: `${100 / 5.2}vw`, // Convert Dimensions.get('window').width to CSS
              backgroundColor: item.lineCount === game.lineCount && !game.roundResult[item.lineCount - 1].isClicked ? '#41B4E6' : '#2F376D',
              borderRadius: '10px',
              borderColor: getColor(item, index),
              borderWidth: '1.7px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderStyle: 'solid',
            }}
          >
            {game.roundResult[item.lineCount - 1].isClicked && (
              <div>
                {item.value === 'bomb' && (
                  <img
                    src={item.isOpened ? Images.bombActive : Images.bombInactive}
                    alt="bomb"

                  />
                )}
                <div>
                  {/* {item.value === 'usdt' && (
                    <img
                      src={Images.usdt_icon}
                      alt="usdt"
                      style={InnerStyle.diamondblur}
                    />
                  )} */}
                  {/* {item.value === 'nft' && (
                    <img
                      src={getShipImage(item?.nftType)}
                      alt="nft"
                      style={InnerStyle.diamondblur}
                    />
                  )} */}
                  {isNumeric(item?.value) && (
                    <img
                      src={item.isOpened ? Images.btncActive : Images.btncblur}
                      alt="btnc"

                    />
                  )}
                </div>
                {item.value !== 'bomb' && item.value !== 'nft' && item.value !== 'usdt' && (
                  <span >{item?.value}</span>
                )}
                {item.value === 'usdt' && (
                  <span >{item?.usdtValue}</span>
                )}
              </div>
            )}
          </div>
        </div>
      </button>
    );
  };

  const manageSound = () => {
    var parms = {
      "enabledSound": !isSound,
    }
    setIsLoading(true);
    dispatch(updateProfile({
      data: parms, callback: (err, data) => {
        setIsLoading(false);
        if (err) {
          return;
        }
        if (!isSound) {
          toast.success("Sound Enable!!")
        } else {
          toast.success("Sound Disable!!")
        }
      }
    }))

  }

  const logoutGame = () => {
    dispatch(userSessionLogout({}));
  }

  const handleEndGame = () => {
    console.log("End Game")
  }

  return (
    <>
      <GameHistoryPop
        history={history}
        setHistory={setHistory}
        historyDetail={historyDetail}
        setHistoryDetail={setHistoryDetail}
      />
      <LeaderBoardPop
        leaderboard={leaderboard}
        setLeaderboard={setLeaderboard}
      />
      <HistoryDetailPop
        historyDetail={historyDetail}
        setHistoryDetail={setHistoryDetail}
      />
      <HowToPlayPop playInst={playInst} setPlayInst={setPlayInst} />
      <BuyPop buy={buy} setBuy={setBuy} />
      <section className={`${styles.gameSec} gameSec position-relative `}>
        <Container>
          <Row>
            <Col
              lg="12"
              className="py-2 sticky-top"
              style={{ background: "#1B1A52" }}
            >
              <div
                className={`${styles.header} box rounded p-2 d-flex align-items-center justify-content-between gap-10`}
              >
                <div className="d-flex align-items-center p-1 rounded">
                  <span className="icn ">
                    <img
                      src={current}
                      alt=""
                      className="img-fluid object-fit-contain"
                      style={{ height: 35 }}
                    />
                  </span>
                  <span className="text-white fw-bold">{gameEnergyLeft} / {settings?.energy?.maxEnergyBalance}</span>
                  <Button
                    onClick={handledBuy}
                    className="d-flex align-items-center justify-content-center border-0 p-0 ms-1"
                    variant="transparent"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <g opacity="0.2">
                        <path
                          d="M21.151 0.697754H6.86047C3.07693 0.697754 0.00976562 3.61229 0.00976562 7.20755V21.4902C0.00976562 25.0855 3.07693 28 6.86047 28H21.151C24.9346 28 28.0017 25.0855 28.0017 21.4902V7.20755C28.0017 3.61229 24.9346 0.697754 21.151 0.697754Z"
                          fill="black"
                        />
                      </g>
                      <path
                        d="M23.4638 0H4.52819C2.02734 0 0 1.92645 0 4.30285V22.9994C0 25.3758 2.02734 27.3022 4.52819 27.3022H23.4638C25.9646 27.3022 27.9919 25.3758 27.9919 22.9994V4.30285C27.9919 1.92645 25.9646 0 23.4638 0Z"
                        fill="#7ED5FB"
                      />
                      <path
                        d="M23.4638 0H4.52819C2.02734 0 0 1.92645 0 4.30285V20.1956C0 22.572 2.02734 24.4985 4.52819 24.4985H23.4638C25.9646 24.4985 27.9919 22.572 27.9919 20.1956V4.30285C27.9919 1.92645 25.9646 0 23.4638 0Z"
                        fill="url(#paint0_linear_0_3047)"
                      />
                      <path
                        d="M20.261 10.8493H16.21V6.99985C16.21 6.44275 15.9771 5.90848 15.5625 5.51455C15.1479 5.12062 14.5857 4.89932 13.9994 4.89932C13.4132 4.89932 12.8509 5.12062 12.4364 5.51455C12.0218 5.90848 11.7889 6.44275 11.7889 6.99985V10.8493H7.73788C7.15161 10.8493 6.58935 11.0706 6.1748 11.4645C5.76024 11.8584 5.52734 12.3927 5.52734 12.9498C5.52734 13.5069 5.76024 14.0412 6.1748 14.4351C6.58935 14.829 7.15161 15.0503 7.73788 15.0503H11.7889V18.8998C11.7889 19.4569 12.0218 19.9911 12.4364 20.3851C12.8509 20.779 13.4132 21.0003 13.9994 21.0003C14.5857 21.0003 15.1479 20.779 15.5625 20.3851C15.9771 19.9911 16.21 19.4569 16.21 18.8998V15.0503H20.261C20.8472 15.0503 21.4095 14.829 21.8241 14.4351C22.2386 14.0412 22.4715 13.5069 22.4715 12.9498C22.4715 12.3927 22.2386 11.8584 21.8241 11.4645C21.4095 11.0706 20.8472 10.8493 20.261 10.8493Z"
                        fill="#215BA4"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_0_3047"
                          x1="13.9935"
                          y1="24.4985"
                          x2="13.9935"
                          y2="0"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#30BAFE" />
                          <stop offset="1" stop-color="#3095FE" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Button>
                </div>
                <div className="d-flex align-items-center p-1 px-2 rounded">
                  <span className="icn me-2">
                    <img
                      src={diamond}
                      alt=""
                      className="img-fluid object-fit-contain"
                      style={{ height: 20 }}
                    />
                  </span>
                  <span className="text-white">{btncBalance}</span>
                </div>
                <Button
                  className="d-flex align-items-center justify-content-center border-0 bg-transparent"
                  variant="transparent"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                  >
                    <path
                      d="M23.5953 3.67742C23.9126 3.37233 23.9126 2.87766 23.5953 2.57257C23.278 2.26748 22.7637 2.26748 22.4464 2.57257L17.6264 7.20714C17.5767 6.66179 17.5117 6.17913 17.4168 5.76017C17.2306 4.9389 16.9003 4.2248 16.2077 3.75673C15.9531 3.58466 15.6696 3.44592 15.375 3.34928C14.4886 3.05843 13.6075 3.29091 12.7127 3.7089C11.8313 4.12058 10.762 4.79857 9.42852 5.64398L9.13952 5.8272C8.65428 6.13483 8.48736 6.23765 8.31473 6.31091C8.12991 6.38935 7.93564 6.44538 7.73644 6.47773C7.55037 6.50793 7.35216 6.51045 6.77093 6.51045L6.59649 6.51039C5.27817 6.50972 4.36215 6.50926 3.54788 6.8715C2.79518 7.20635 2.0707 7.86179 1.67731 8.56383C1.25198 9.32287 1.20207 10.1167 1.13111 11.2453L1.12254 11.3812C1.09822 11.7649 1.08334 12.1443 1.08334 12.5C1.08334 12.8558 1.09822 13.2352 1.12254 13.6189L1.13111 13.7547C1.20207 14.8834 1.25198 15.6772 1.67731 16.4362C2.0707 17.1382 2.79518 17.7937 3.54788 18.1285C4.20931 18.4228 4.93788 18.4777 5.89465 18.4876L2.94632 21.3226C2.62902 21.6277 2.62902 22.1223 2.94632 22.4274C3.26362 22.7325 3.77807 22.7325 4.09537 22.4274L23.5953 3.67742Z"
                      fill="#31B8FF"
                    />
                    <g opacity="0.4">
                      <path
                        d="M17.5914 9.45026L8.24875 18.4336C8.67701 18.9283 9.77807 19.5977 10.1169 19.7919C11.2423 20.5009 12.1737 21.0676 12.9677 21.4053C13.7719 21.7474 14.5709 21.9147 15.3748 21.6509C15.6693 21.5543 15.9528 21.4155 16.2074 21.2434C16.9724 20.7266 17.2962 19.9099 17.4716 18.973C17.644 18.0518 17.703 16.831 17.7764 15.3103L17.7793 15.2504C17.8354 14.089 17.8748 13.0752 17.8748 12.5002C17.8748 12.4718 17.875 12.4336 17.8752 12.3871C17.8782 11.7963 17.8882 9.857 17.5914 9.45026Z"
                        fill="#31B8FF"
                      />
                      <path
                        d="M22.2238 6.57976C22.6328 6.40231 23.114 6.57729 23.2985 6.97058C23.8045 8.04885 24.3748 9.85389 24.3748 12.5002C24.3748 14.783 23.9503 16.4406 23.5081 17.5462C23.2873 18.0981 23.0633 18.5097 22.8879 18.7908C22.8002 18.9312 22.7248 19.039 22.6683 19.1151C22.6399 19.1532 22.6164 19.1834 22.5983 19.2059C22.5892 19.2172 22.5816 19.2265 22.5755 19.2339L22.5673 19.2435L22.5641 19.2473L22.5627 19.2489C22.5627 19.2489 22.5615 19.2503 21.9388 18.7514L22.5615 19.2503C22.2742 19.5818 21.7619 19.6266 21.4172 19.3503C21.0741 19.0754 21.0263 18.5862 21.3089 18.2548L21.3127 18.2502L21.3237 18.2362C21.3295 18.2287 21.337 18.219 21.346 18.207C21.3782 18.1635 21.4297 18.0905 21.4945 17.9869C21.6237 17.7797 21.806 17.4492 21.9914 16.9853C22.3617 16.0597 22.7498 14.5923 22.7498 12.5002C22.7498 10.0769 22.2291 8.49079 21.8173 7.61317C21.6327 7.21988 21.8147 6.7572 22.2238 6.57976Z"
                        fill="#31B8FF"
                      />
                      <path
                        d="M20.909 10.258C20.8178 9.83556 20.3877 9.56419 19.9483 9.65191C19.5089 9.73962 19.2267 10.1532 19.3179 10.5756C19.4138 11.0197 19.4998 11.6535 19.4998 12.5002C19.4998 13.5313 19.3722 14.2472 19.2544 14.689C19.1955 14.9101 19.1387 15.0635 19.1009 15.1544C19.0819 15.1998 19.0677 15.2297 19.0603 15.2447L19.0548 15.2554C18.8449 15.631 18.9871 16.1003 19.3761 16.3081C19.7683 16.5176 20.263 16.3818 20.4808 16.0046L19.7718 15.6258C20.4808 16.0046 20.4816 16.0032 20.4816 16.0032L20.4825 16.0018L20.4843 15.9985L20.4884 15.9911L20.4989 15.9721C20.5067 15.9574 20.5164 15.939 20.5274 15.9165C20.5496 15.8717 20.5778 15.8113 20.6096 15.7347C20.6733 15.5814 20.752 15.3638 20.8285 15.077C20.9816 14.5031 21.1248 13.6566 21.1248 12.5002C21.1248 11.5511 21.0282 10.8106 20.909 10.258Z"
                        fill="#31B8FF"
                      />
                    </g>
                  </svg>
                </Button>
                <Button
                  onClick={() => logoutGame()}
                  className={`${styles.bgBtn} d-flex align-items-center justify-content-center`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      d="M0 2.375C0 1.74511 0.231787 1.14102 0.644372 0.695621C1.05696 0.250222 1.61654 0 2.20002 0H12.1001C12.6836 0 13.2432 0.250222 13.6558 0.695621C14.0684 1.14102 14.3002 1.74511 14.3002 2.375V4.75C14.3002 5.06494 14.1843 5.36699 13.978 5.58969C13.7717 5.81239 13.4919 5.9375 13.2001 5.9375C12.9084 5.9375 12.6286 5.81239 12.4223 5.58969C12.216 5.36699 12.1001 5.06494 12.1001 4.75V2.375H2.20002V16.625H12.1001V14.25C12.1001 13.9351 12.216 13.633 12.4223 13.4103C12.6286 13.1876 12.9084 13.0625 13.2001 13.0625C13.4919 13.0625 13.7717 13.1876 13.978 13.4103C14.1843 13.633 14.3002 13.9351 14.3002 14.25V16.625C14.3002 17.2549 14.0684 17.859 13.6558 18.3044C13.2432 18.7498 12.6836 19 12.1001 19H2.20002C1.61654 19 1.05696 18.7498 0.644372 18.3044C0.231787 17.859 0 17.2549 0 16.625V2.375ZM16.8225 5.09794C17.0288 4.87532 17.3085 4.75025 17.6002 4.75025C17.8919 4.75025 18.1716 4.87532 18.3779 5.09794L21.6779 8.66044C21.8842 8.88313 22 9.18512 22 9.5C22 9.81488 21.8842 10.1169 21.6779 10.3396L18.3779 13.9021C18.1704 14.1184 17.8926 14.2381 17.6041 14.2354C17.3157 14.2327 17.0398 14.1078 16.8359 13.8876C16.6319 13.6674 16.5162 13.3696 16.5137 13.0582C16.5112 12.7469 16.6221 12.4469 16.8225 12.2229L18.2448 10.6875H7.70008C7.40834 10.6875 7.12855 10.5624 6.92226 10.3397C6.71596 10.117 6.60007 9.81494 6.60007 9.5C6.60007 9.18506 6.71596 8.88301 6.92226 8.66031C7.12855 8.43761 7.40834 8.3125 7.70008 8.3125H18.2448L16.8225 6.77706C16.6163 6.55437 16.5004 6.25238 16.5004 5.9375C16.5004 5.62262 16.6163 5.32063 16.8225 5.09794Z"
                      fill="#31B8FF"
                    />
                  </svg>
                </Button>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div
                className={`${styles.gameBoxWrpper} gameBoxWrpper d-flex align-items-center justify-content-center flex-column`}
              >
                <div className={`${styles.gameBox} box rounded p-3 w-100`}>
                  <div className="d-flex align-items-center gap-10">
                    <ul
                      className={`${styles.countList} list-unstyled ps-0 mb-0 position-relative`}
                    >
                      {data &&
                        data.length > 0 &&
                        data.map((item, key) => (
                          <li
                            key={key}
                            className="d-flex align-items-center"
                            style={{ height: height, gap: 8 }}
                          >
                            <span
                              className="text-white fw-bold position-relative"
                              style={{ width: 20 }}
                            >
                              {data.length - key}
                            </span>
                            <span className="icn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="9"
                                viewBox="0 0 10 9"
                                fill="none"
                              >
                                <rect
                                  width="10"
                                  height="9"
                                  rx="3"
                                  fill="url(#paint0_linear_0_3020)"
                                />
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_0_3020"
                                    x1="5"
                                    y1="0"
                                    x2="5"
                                    y2="9"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#4D88B8" />
                                    <stop
                                      offset="1"
                                      stop-color="#2A92E7"
                                      stop-opacity="0.61"
                                    />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </span>
                          </li>
                        ))}
                    </ul>
                    <ul
                      className={`${styles.gameOptionWrp} list-unstyled ps-0 mb-0`}
                    >
                      {data &&
                        data.length > 0 &&
                        data.map((item, key) => (
                          <li
                            className="d-flex align-items-center justify-content-center"
                            ref={elementRef}
                          >
                            {item.level &&
                              item.level.map((data, index) => (
                                <div
                                  key={index}
                                  className={`${styles.option} ${data ? styles.active : ""
                                    }`}
                                  onClick={() =>
                                    handleOptionClick(data, item.id, index)
                                  }
                                ></div>
                              ))}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg="12"
              className=" sticky-bottom py-3"
              style={{
                background:
                  "linear-gradient(0deg, rgba(53,124,153,1) 0%, rgba(72,130,161,1) 35%, rgba(108,143,178,1) 100%)",
              }}
            >
              <div
                className={`${styles.ctcBtn} d-flex align-items-center justify-content-center gap-10`}
              >
                <Button
                  onClick={handleLeaderBoard}
                  className="d-flex align-items-center justify-content-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                  >
                    <path
                      d="M20.0588 29.106H21.4265V22.4772H28.2647C28.7683 22.4772 29.1765 22.9012 29.1765 23.4242V29.106H30.5441C30.7959 29.106 31 29.318 31 29.5795V30.5265C31 30.788 30.7959 31 30.5441 31H0.455882C0.204105 31 0 30.788 0 30.5265V29.5795C0 29.318 0.204105 29.106 0.455882 29.106H1.82353V21.0567C1.82353 20.5337 2.23174 20.1098 2.73529 20.1098H9.57353V29.106H10.9412V16.7953C10.9412 16.2723 11.3494 15.8484 11.8529 15.8484H19.1471C19.6506 15.8484 20.0588 16.2723 20.0588 16.7953V29.106ZM13.3804 10.7366C12.4868 11.2245 11.5616 10.517 11.7309 9.49184L12.1357 7.04046L10.4209 5.30438C9.69794 4.57247 10.0599 3.43996 11.0509 3.29039L13.4207 2.93273L14.4806 0.702391C14.9274 -0.237896 16.0762 -0.230357 16.5194 0.702391L17.5793 2.93273L19.9491 3.29039C20.9481 3.44117 21.2963 4.57833 20.5791 5.30438L18.8643 7.04046L19.2691 9.49184C19.4398 10.5253 18.5061 11.2206 17.6196 10.7366L15.5 9.57917L13.3804 10.7366Z"
                      fill="#72CCFF"
                    />
                  </svg>
                </Button>
                {isGameStart ?
                  <Button
                    onClick={handleEndGame}
                    className="d-flex align-items-center justify-content-center w-100 fw-bold"
                  >
                    End
                  </Button>
                  :

                  <Button
                    onClick={handleGameStart}
                    className="d-flex align-items-center justify-content-center w-100 fw-bold"
                  >
                    Start
                  </Button>
                }
                <Dropdown>
                  <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="22"
                      viewBox="0 0 27 22"
                      fill="none"
                    >
                      <rect
                        width="26.6034"
                        height="4.63636"
                        rx="2.31818"
                        fill="#3A488C"
                      />
                      <rect
                        y="8.34546"
                        width="26.6034"
                        height="4.63636"
                        rx="2.31818"
                        fill="#3A488C"
                      />
                      <rect
                        y="16.6909"
                        width="26.6034"
                        height="4.63636"
                        rx="2.31818"
                        fill="#3A488C"
                      />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={`${styles.DropdownMenu} p-0 pb-2`}>
                    <Button
                      className="border-0 px-2 py-1"
                      variant="transparent"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="12"
                        viewBox="0 0 9 12"
                        fill="none"
                      >
                        <path
                          d="M1.9071 5.99974L7.88965 1.48358C8.03678 1.3725 8.03678 1.19437 7.88965 1.0833C7.74251 0.972232 7.50654 0.972232 7.35941 1.0833L1.11035 5.80065C0.963216 5.91172 0.963216 6.08985 1.11035 6.20092L7.35941 10.9162C7.43159 10.9707 7.52875 11 7.62314 11C7.71753 11 7.81469 10.9728 7.88687 10.9162C8.03401 10.8051 8.03401 10.627 7.88687 10.5159L1.9071 5.99974Z"
                          fill="white"
                          stroke="white"
                          stroke-width="1.5"
                        />
                      </svg>
                    </Button>
                    <Dropdown.Item
                      onClick={handleHistory}
                      className={`${styles.item} d-flex align-items-center text-white`}
                      style={{ fontSize: 13 }}
                      href="#/action-1"
                    >
                      <span className="icn me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            d="M6.47401 0.575037C7.38888 0.96703 8.15216 1.6603 8.64565 2.5475C9.13908 3.43468 9.33525 4.46628 9.20367 5.48249C9.07209 6.49869 8.62013 7.44282 7.91779 8.16868C7.21545 8.89449 6.30196 9.3615 5.31875 9.49734C4.33555 9.63324 3.33752 9.43034 2.47924 8.92016C1.62096 8.40998 0.950328 7.62099 0.57121 6.67532C0.452231 6.37853 0.588865 6.03744 0.876388 5.91352C1.16392 5.78953 1.49346 5.92961 1.61244 6.2264C1.89678 6.93562 2.39975 7.52742 3.04346 7.91001C3.68717 8.29266 4.43569 8.44482 5.17309 8.34292C5.91051 8.24103 6.5956 7.89077 7.12235 7.34642C7.64911 6.80206 7.98807 6.09394 8.08674 5.33177C8.18547 4.56961 8.03832 3.79592 7.66822 3.13053C7.29812 2.46513 6.7257 1.94518 6.03955 1.65119C5.3534 1.35719 4.59186 1.30557 3.87288 1.50432C3.40246 1.63435 2.96743 1.86682 2.59541 2.1827L2.98652 2.62993C3.32256 3.01417 3.04002 3.6281 2.53874 3.6029L0.293311 3.49002L0.409998 1.16876C0.436048 0.65055 1.05516 0.421498 1.39121 0.805746L1.84038 1.31936C2.34544 0.880001 2.94035 0.557447 3.58513 0.379214C4.54378 0.11422 5.55917 0.183048 6.47401 0.575037ZM4.85312 2.55295C5.16388 2.56857 5.4027 2.84166 5.38655 3.1629L5.31094 4.66698L6.22155 5.70823C6.42986 5.94643 6.41134 6.31473 6.1802 6.53083C5.94906 6.74693 5.59279 6.72902 5.38447 6.49083L4.16139 5.09227L4.26122 3.10634C4.27736 2.78509 4.54237 2.53733 4.85312 2.55295Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      History
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handlePlayInst}
                      className={`${styles.item} d-flex align-items-center text-white`}
                      style={{ fontSize: 13 }}
                      href="#/action-1"
                    >
                      <span className="icn me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                        >
                          <path
                            d="M5.41634 10C7.85542 10 9.83268 7.98528 9.83268 5.5C9.83268 3.01472 7.85542 1 5.41634 1C2.97726 1 1 3.01472 1 5.5C1 7.98528 2.97726 10 5.41634 10Z"
                            stroke="white"
                            stroke-width="1.3"
                          />
                          <path
                            d="M4.58789 4.09375C4.58789 3.62776 4.95864 3.25 5.41595 3.25C5.87327 3.25 6.24402 3.62776 6.24402 4.09375C6.24402 4.4031 6.08061 4.67358 5.83701 4.82046C5.62706 4.947 5.41595 5.13897 5.41595 5.3875V5.95"
                            stroke="white"
                            stroke-width="1.3"
                            stroke-linecap="round"
                          />
                          <path
                            d="M5.41624 7.74979C5.66015 7.74979 5.85788 7.54832 5.85788 7.29979C5.85788 7.05126 5.66015 6.84979 5.41624 6.84979C5.17234 6.84979 4.97461 7.05126 4.97461 7.29979C4.97461 7.54832 5.17234 7.74979 5.41624 7.74979Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      How to play
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Game;
