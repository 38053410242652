/* Instruments */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../helper/url_helper";
import { get } from "../../../helper/api_helper";

export const getSettings = createAsyncThunk(
  "getSettings",
  async ({ data, callBack }, Thunk) => {
    console.log("settings", data)
    try {
      const response = await get(URL.GET_SETTINGS);
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      // toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


// export const getLanguages = createAsyncThunk(
//   "getLanguages",
//   async ({ data, callBack }, Thunk) => {
//     console.log("data", data)
//     try {
//       const response = await post(URL.GET_LANGUAGES, data);
//       callBack && callBack(null, response);
//       return response.data;
//     } catch (error) {
//       console.log(error, "<===error");
//       // toast.error(typeof error === "string" ? error : error?.message)
//       callBack && callBack(error);
//       return Thunk.rejectWithValue(error);
//     }
//   }
// );

// export const getContentPages = createAsyncThunk(
//   "getContentPages",
//   async ({ data, callBack }, Thunk) => {
//     console.log("data", data)
//     try {
//       const response = await post(URL.GET_CONTENT_PAGES, data);
//       callBack && callBack(null, response);
//       return response.data;
//     } catch (error) {
//       console.log(error, "<===error");
//       // toast.error(typeof error === "string" ? error : error?.message)
//       callBack && callBack(error);
//       return Thunk.rejectWithValue(error);
//     }
//   }
// );


// export const getFaqList = createAsyncThunk(
//   "getFaqList",
//   async ({ data, callBack }, Thunk) => {
//     console.log("data", data)
//     try {
//       const response = await post(URL.GET_FAQ, data);
//       callBack && callBack(null, response);
//       return response.data;
//     } catch (error) {
//       console.log(error, "<===error");
//       // toast.error(typeof error === "string" ? error : error?.message)
//       callBack && callBack(error);
//       return Thunk.rejectWithValue(error);
//     }
//   }
// );



