import React, { useState } from "react";
import { Button, Dropdown, Modal, Nav, Tab } from "react-bootstrap";
import styles from "./GameHistoryPop.module.scss";

// img
import p1 from "../../../assets/images/current.png";
import diamond from "../../../assets/images/diamond.png";

const GameHistoryPop = ({
  history,
  setHistory,
  historyDetail,
  setHistoryDetail,
}) => {
  const handleHistory = () => {
    setHistory(!history);
  };
  const handleHistoryDetail = () => {
    setHistoryDetail(!historyDetail);
    setHistory(false);
  };
  return (
    <>
      <Modal
        show={history}
        onHide={handleHistory}
        backdrop="true"
        centered
        scrollable={true}
        className={`${styles.HistoryPop} bottomPop`}
        keyboard={false}
      >
        <Modal.Body className="p-0 pt-2">
          <Button
            onClick={handleHistory}
            variant="transparent"
            className="border-0 p-0 position-absolute"
            style={{ top: 10, left: 10 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 23"
              fill="none"
            >
              <path
                d="M3.55503 11.4995L13.8108 2.91879C14.0631 2.70776 14.0631 2.36931 13.8108 2.15828C13.5586 1.94724 13.1541 1.94724 12.9018 2.15828L2.18917 11.1212C1.93694 11.3323 1.93694 11.6707 2.18917 11.8818L12.9018 20.8407C13.0256 20.9443 13.1921 21 13.354 21C13.5158 21 13.6823 20.9482 13.8061 20.8407C14.0583 20.6297 14.0583 20.2912 13.8061 20.0802L3.55503 11.4995Z"
                fill="white"
                stroke="white"
                stroke-width="2.5"
              />
            </svg>
          </Button>
          <div className="top pb-3 text-center">
            <p
              className="m-0 text-white d-inline-block pb-1 px-3"
              style={{ borderBottom: "1px solid #356BBF" }}
            >
              Game history
            </p>
          </div>
          <div className="table-responsive">
            <table className="table text-center">
              <thead>
                <tr>
                  <th className="border-0 bg-transparent fw-normal">
                    Round ID
                  </th>
                  <th className="border-0 bg-transparent fw-normal">Line</th>
                  <th className="border-0 bg-transparent fw-normal">Claim</th>
                  <th className="border-0 bg-transparent fw-normal">Date</th>
                  <th className="border-0 bg-transparent fw-normal"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">896bf272d </h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">3 </h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <Dropdown className={`${styles.dropdown}`}>
                      <Dropdown.Toggle
                        variant="transparent"
                        id="dropdown-basic"
                        className="rounded-2 d-flex align-items-center justify-content-center px-1 border-0"
                      >
                        <span className="icn me-1">
                          <img
                            src={diamond}
                            alt=""
                            className="img-fluid object-fit-contain"
                            style={{ height: 15, width: 15 }}
                          />
                        </span>
                        See all...
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className={`${styles.DropdownMenu} p-0 pb-2`}
                      >
                        <Button
                          className="border-0 px-2 py-1"
                          variant="transparent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="12"
                            viewBox="0 0 9 12"
                            fill="none"
                          >
                            <path
                              d="M1.9071 5.99974L7.88965 1.48358C8.03678 1.3725 8.03678 1.19437 7.88965 1.0833C7.74251 0.972232 7.50654 0.972232 7.35941 1.0833L1.11035 5.80065C0.963216 5.91172 0.963216 6.08985 1.11035 6.20092L7.35941 10.9162C7.43159 10.9707 7.52875 11 7.62314 11C7.71753 11 7.81469 10.9728 7.88687 10.9162C8.03401 10.8051 8.03401 10.627 7.88687 10.5159L1.9071 5.99974Z"
                              fill="white"
                              stroke="white"
                              stroke-width="1.5"
                            />
                          </svg>
                        </Button>
                        <ol className="list-unstyled ps-0 mb-0">
                          <li className="d-flex align-items-center px-2">
                            <span className="numb text-white fw-bold">1.</span>
                            <Button
                              className="d-flex align-items-center text-white py-1"
                              variant="transparent"
                            >
                              <span className="icn me-1">
                                <img
                                  src={diamond}
                                  alt=""
                                  style={{ height: 12, width: 12 }}
                                  className="img-fluid object-fit-contain"
                                />
                              </span>
                              200
                            </Button>
                          </li>
                          <li className="d-flex align-items-center px-2">
                            <span className="numb text-white fw-bold">2.</span>
                            <Button
                              className="d-flex align-items-center text-white py-1"
                              variant="transparent"
                            >
                              <span className="icn me-1">
                                <img
                                  src={diamond}
                                  alt=""
                                  style={{ height: 12, width: 12 }}
                                  className="img-fluid object-fit-contain"
                                />
                              </span>
                              200
                            </Button>
                          </li>
                        </ol>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    04.05.24 <br /> 18:45:30
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <Button
                      onClick={handleHistoryDetail}
                      variant="transparent"
                      className="border-0 p-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="10"
                        viewBox="0 0 19 10"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.50784 7.23569C3.1735 6.41775 2.22044 5.48287 1.77333 5C2.22044 4.51713 3.1735 3.58224 4.50784 2.76434C5.88545 1.91991 7.58648 1.25 9.49997 1.25C11.4135 1.25 13.1146 1.91991 14.4922 2.76434C15.8266 3.58224 16.7796 4.51713 17.2267 5C16.7796 5.48287 15.8266 6.41775 14.4922 7.23569C13.1146 8.08012 11.4135 8.75 9.49997 8.75C7.58648 8.75 5.88545 8.08012 4.50784 7.23569ZM9.49997 0C7.00111 0 4.90705 0.871638 3.36605 1.81622C1.81946 2.76423 0.746216 3.836 0.269914 4.35612C-0.0899714 4.74906 -0.0899714 5.25094 0.269914 5.64388C0.746216 6.164 1.81946 7.23575 3.36605 8.18381C4.90705 9.12838 7.00111 10 9.49997 10C11.9989 10 14.093 9.12838 15.634 8.18381C17.1806 7.23575 18.2538 6.164 18.7301 5.64388C19.09 5.25094 19.09 4.74906 18.7301 4.35612C18.2538 3.836 17.1806 2.76423 15.634 1.81622C14.093 0.871638 11.9989 0 9.49997 0ZM7.74789 5C7.74789 4.30963 8.53239 3.75 9.49997 3.75C10.4676 3.75 11.2521 4.30963 11.2521 5C11.2521 5.69037 10.4676 6.25 9.49997 6.25C8.53239 6.25 7.74789 5.69037 7.74789 5ZM9.49997 2.5C7.56472 2.5 5.99585 3.61929 5.99585 5C5.99585 6.38069 7.56472 7.5 9.49997 7.5C11.4353 7.5 13.0041 6.38069 13.0041 5C13.0041 3.61929 11.4353 2.5 9.49997 2.5Z"
                          fill="#9DA1C3"
                        />
                      </svg>
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">896bf272d </h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <h6 className="m-0 fw-normal text-white">3 </h6>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <Button
                      className="d-flex align-items-center text-white py-1"
                      variant="transparent"
                    >
                      <span className="icn me-1">
                        <img
                          src={diamond}
                          alt=""
                          style={{ height: 12, width: 12 }}
                          className="img-fluid object-fit-contain"
                        />
                      </span>
                      200
                    </Button>
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    04.05.24 <br /> 18:45:30
                  </td>
                  <td className="border-0 bg-transparent px-2 py-3">
                    <Button variant="transparent" className="border-0 p-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="10"
                        viewBox="0 0 19 10"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.50784 7.23569C3.1735 6.41775 2.22044 5.48287 1.77333 5C2.22044 4.51713 3.1735 3.58224 4.50784 2.76434C5.88545 1.91991 7.58648 1.25 9.49997 1.25C11.4135 1.25 13.1146 1.91991 14.4922 2.76434C15.8266 3.58224 16.7796 4.51713 17.2267 5C16.7796 5.48287 15.8266 6.41775 14.4922 7.23569C13.1146 8.08012 11.4135 8.75 9.49997 8.75C7.58648 8.75 5.88545 8.08012 4.50784 7.23569ZM9.49997 0C7.00111 0 4.90705 0.871638 3.36605 1.81622C1.81946 2.76423 0.746216 3.836 0.269914 4.35612C-0.0899714 4.74906 -0.0899714 5.25094 0.269914 5.64388C0.746216 6.164 1.81946 7.23575 3.36605 8.18381C4.90705 9.12838 7.00111 10 9.49997 10C11.9989 10 14.093 9.12838 15.634 8.18381C17.1806 7.23575 18.2538 6.164 18.7301 5.64388C19.09 5.25094 19.09 4.74906 18.7301 4.35612C18.2538 3.836 17.1806 2.76423 15.634 1.81622C14.093 0.871638 11.9989 0 9.49997 0ZM7.74789 5C7.74789 4.30963 8.53239 3.75 9.49997 3.75C10.4676 3.75 11.2521 4.30963 11.2521 5C11.2521 5.69037 10.4676 6.25 9.49997 6.25C8.53239 6.25 7.74789 5.69037 7.74789 5ZM9.49997 2.5C7.56472 2.5 5.99585 3.61929 5.99585 5C5.99585 6.38069 7.56472 7.5 9.49997 7.5C11.4353 7.5 13.0041 6.38069 13.0041 5C13.0041 3.61929 11.4353 2.5 9.49997 2.5Z"
                          fill="#9DA1C3"
                        />
                      </svg>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GameHistoryPop;
