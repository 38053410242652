import React, { useState } from "react";
import { Button, Dropdown, Modal, Nav, Tab } from "react-bootstrap";
import styles from "./HistoryDetailPop.module.scss";

// img
import p1 from "../../../assets/images/current.png";
import diamond from "../../../assets/images/diamond.png";
import bomb from "../../../assets/images/bomb.png";

const HistoryDetailPop = ({ historyDetail, setHistoryDetail }) => {
  const handleHistoryDetail = () => {
    setHistoryDetail(!historyDetail);
  };
  return (
    <>
      <Modal
        show={historyDetail}
        onHide={handleHistoryDetail}
        backdrop="true"
        scrollable={true}
        centered
        className={`${styles.HistoryDetailPop} historyDetail`}
        keyboard={false}
      >
        <Modal.Body className="p-2">
          <Button
            onClick={handleHistoryDetail}
            variant="transparent"
            className="border-0 p-0 position-absolute"
            style={{ top: 5, right: 5 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M11.0185 8.49727L16.4832 3.03462C17.1723 2.34585 17.1723 1.20582 16.4832 0.517056C16.1479 0.185781 15.6954 0 15.224 0C14.7525 0 14.3 0.185781 13.9647 0.517056L8.5 5.9797L3.03529 0.517056C2.69995 0.185781 2.24749 0 1.77603 0C1.30457 0 0.852109 0.185781 0.516771 0.517056C-0.172257 1.20582 -0.172257 2.34585 0.516771 3.03462L5.98148 8.49727L0.516771 13.9599C-0.172257 14.6487 -0.172257 15.7887 0.516771 16.4775C0.873166 16.8337 1.3246 17 1.77603 17C2.22746 17 2.67889 16.8337 3.03529 16.4775L8.5 11.0148L13.9647 16.4775C14.3211 16.8337 14.7725 17 15.224 17C15.6754 17 16.1268 16.8337 16.4832 16.4775C17.1723 15.7887 17.1723 14.6487 16.4832 13.9599L11.0185 8.49727V8.49727Z"
                fill="#31B8FF"
              />
            </svg>
          </Button>
          <div
            className={`${styles.top} top rounded p-2 px-3 mb-1`}
            style={{ background: "#3A4085" }}
          >
            <div className="d-flex align-items-center gap-10">
              <div
                className="p-1 rounded text-white"
                style={{ background: "#494D95" }}
              >
                ID:896bf272d
              </div>
              <div
                className="p-1 rounded d-flex align-items-center gap-10"
                style={{ background: "#494D95" }}
              >
                <span style={{ color: "#A2A7CE" }}>04.05.24</span>{" "}
                <span className="" style={{ color: "#A2A7CE" }}>
                  18:45:30
                </span>
              </div>
            </div>
            <div className="mt-2">
              <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center justify-content-between gap-10">
                <li className="">
                  <p className="m-0 text-white">Claim</p>
                  <div
                    className="p-1 rounded text-white px-2"
                    style={{ background: "#494D95" }}
                  >
                    <img
                      src={diamond}
                      style={{ height: 12 }}
                      alt=""
                      className="img-fluid object-fit-contain flex-shrink-0 me-1"
                    />
                    <span className="themeClr fw-sbold">240</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={`${styles.historyList} historyList rounded p-2 px-3 mt-1`}
            style={{ background: "#3A4085" }}
          >
            <p className="m-0 text-white" style={{ fontSize: 14 }}>
              Result:
            </p>
            <ol className="mb-0 list-unstyled ps-0 pt-2">
              <li className="d-flex align-items-center" style={{ gap: 1 }}>
                <div
                  className={`${styles.numb} numb flex-shrink-0 me-2 text-white fw-bold`}
                >
                  1
                </div>
                <div className="optionWRp d-flex align-items-center w-100">
                  <div
                    className={`${styles.optionHist} p-2 position-relative d-flex align-items-center justify-content-center`}
                  >
                    <img
                      src={diamond}
                      alt=""
                      className="img-fluid object-fit-contain h-100 w-100"
                    />
                    <span
                      className="themeClr position-absolute fw-bold"
                      style={{ bottom: 2, right: 3 }}
                    >
                      2
                    </span>
                  </div>
                  <div
                    className={`${styles.optionHist} p-2 position-relative d-flex align-items-center justify-content-center`}
                  >
                    <img
                      src={bomb}
                      alt=""
                      className="img-fluid object-fit-contain h-100 w-100"
                    />
                  </div>
                  <div
                    className={`${styles.optionHist} p-2 position-relative d-flex align-items-center justify-content-center`}
                  ></div>
                  <div
                    className={`${styles.optionHist} p-2 position-relative d-flex align-items-center justify-content-center`}
                  ></div>
                </div>
              </li>
              <li className="d-flex align-items-center" style={{ gap: 1 }}>
                <div
                  className={`${styles.numb} numb flex-shrink-0 me-2 text-white fw-bold`}
                >
                  2
                </div>
                <div className="optionWRp d-flex align-items-center w-100">
                  <div
                    className={`${styles.optionHist} ${styles.active} p-2 position-relative d-flex align-items-center justify-content-center`}
                  >
                    <img
                      src={diamond}
                      alt=""
                      className="img-fluid object-fit-contain h-100 w-100"
                    />
                    <span
                      className="themeClr position-absolute fw-bold"
                      style={{ bottom: 2, right: 3 }}
                    >
                      2
                    </span>
                  </div>
                  <div
                    className={`${styles.optionHist} ${styles.bomb} p-2 position-relative d-flex align-items-center justify-content-center`}
                  >
                    <img
                      src={bomb}
                      alt=""
                      className="img-fluid object-fit-contain h-100 w-100"
                    />
                  </div>
                  <div
                    className={`${styles.optionHist} p-2 position-relative d-flex align-items-center justify-content-center`}
                  ></div>
                  <div
                    className={`${styles.optionHist} p-2 position-relative d-flex align-items-center justify-content-center`}
                  ></div>
                </div>
              </li>
            </ol>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HistoryDetailPop;
