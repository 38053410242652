import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// PrivateRoute component as a wrapper for protected routes
const PrivateRoute = ({ children }) => {
    const { user, loading } = useSelector(({ Auth }) => Auth);
    console.log("user", user, "loading", loading);

    // While checking auth, show a loading indicator
    if (loading) {
        return <p>Loading...</p>;
    }

    // If authenticated, render the child component (protected content)
    if (user?._id) {
        return children;
    }

    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
};

export default PrivateRoute;