import React from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
// img
import h1 from "../../../assets/images/hgame.png";
import h2 from "../../../assets/images/hleaderboard.png";

const HowToPlayPop = ({ playInst, setPlayInst }) => {
  const handlePlayInst = () => {
    setPlayInst(!playInst);
  };
  return (
    <>
      <Modal
        show={playInst}
        onHide={handlePlayInst}
        backdrop="true"
        centered
        keyboard={false}
      >
        <Modal.Body>
          <Button
            onClick={handlePlayInst}
            variant="transparent"
            className="border-0 p-0 position-absolute"
            style={{ top: 5, right: 5 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M11.0185 8.49727L16.4832 3.03462C17.1723 2.34585 17.1723 1.20582 16.4832 0.517056C16.1479 0.185781 15.6954 0 15.224 0C14.7525 0 14.3 0.185781 13.9647 0.517056L8.5 5.9797L3.03529 0.517056C2.69995 0.185781 2.24749 0 1.77603 0C1.30457 0 0.852109 0.185781 0.516771 0.517056C-0.172257 1.20582 -0.172257 2.34585 0.516771 3.03462L5.98148 8.49727L0.516771 13.9599C-0.172257 14.6487 -0.172257 15.7887 0.516771 16.4775C0.873166 16.8337 1.3246 17 1.77603 17C2.22746 17 2.67889 16.8337 3.03529 16.4775L8.5 11.0148L13.9647 16.4775C14.3211 16.8337 14.7725 17 15.224 17C15.6754 17 16.1268 16.8337 16.4832 16.4775C17.1723 15.7887 17.1723 14.6487 16.4832 13.9599L11.0185 8.49727V8.49727Z"
                fill="#31B8FF"
              />
            </svg>
          </Button>
          <div className="top pb-3 pt-2 text-center">
            <h5
              className="m-0 text-white d-inline-block  pb-1 px-3"
              style={{ borderBottom: "1px solid #356BBF" }}
            >
              How to play
            </h5>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Game">
            <Nav
              variant="pills"
              className="justify-content-center inline-flex rounded-3 mx-auto p-1 PillsTab"
              style={{ background: "#283373", maxWidth: "max-content" }}
            >
              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center justify-content-center p-1"
                  eventKey="Game"
                >
                  Game
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center justify-content-center p-1"
                  eventKey="Leaderboard"
                >
                  Leaderboard
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content
              className="pt-3"
              style={{ fontSize: 14, lineHeight: "20px" }}
            >
              <Tab.Pane eventKey="Game">
                <div className="py-2">
                  <p className="m-0 text-white">
                    Clicking "Start" reveals four blue squares; select one to
                    find either Crystals or a Bomb. Finding a Crystal lets you
                    proceed or “Claim” your reward, but a Bomb means losing your
                    accumulated Crystals.
                  </p>
                </div>
                <div className="py-2 text-center">
                  <img src={h1} alt="" className="img-fluid" />
                </div>
                <div className="py-2">
                  <ul className="ps-3 mb-0">
                    <li className="text-white">
                      Level 1 pirate holders receive 1 free energy daily.
                    </li>
                    <li className="text-white">
                      Level 2 pirate holders receive 2 free energy daily.
                    </li>
                    <li className="text-white">
                      Level 3 pirate holders receive 3 free energy daily.
                    </li>
                  </ul>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Leaderboard">
                <div className="py-2">
                  <ul className="ps-3 mb-0">
                    <li className="text-white">
                      The leaderboard scoring system is very simple: reaching
                      line X earns you X points.
                    </li>
                    <li className="text-white">
                      For example, reaching line 5 earns you 5 points, and
                      reaching line 7 earns you 7 points.
                    </li>
                    <li className="text-white">
                      If you successfully open 2 squares but hit a bomb on the
                      3rd, you've earned 2 points.
                    </li>{" "}
                    <li className="text-white">
                      Achieving line 10 awards you 100 points.
                    </li>{" "}
                    <li className="text-white">
                      The leaderboard duration is one week and resets every
                      Monday.
                    </li>
                  </ul>
                </div>
                <div className="py-2 text-center">
                  <img src={h2} alt="" className="img-fluid" />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HowToPlayPop;
