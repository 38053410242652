import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translation files
import translationEN from "./locales/en/translation.json";
// import translationFR from "./locales/fr/translation.json";

// Define the available translations
const resources = {
  en: {
    translation: translationEN,
  },
  //   fr: {
  //     translation: translationFR,
  //   },
};

// Initialize i18n
i18n
  .use(LanguageDetector) // Detect language from browser
  .use(initReactI18next) // Bind i18n to React
  .init({
    resources,
    fallbackLng: "en", // Use English as the fallback language
    interpolation: {
      escapeValue: false, // React already handles escaping
    },
  });

export default i18n;
