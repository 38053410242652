import { store } from "../lib/store";
import CryptoJS from 'crypto-js';
import { getSSMParameter } from "./secretManager";

export const isMobile = () => {
  return typeof window === "object" && window.innerWidth <= 768; // Adjust the threshold as needed
};

export const playSound = (sound) => {
  const state = store.getState();
  if (state?.Auth?.user?.enabledSound) {
    try {
      const audio = new Audio(sound);
      audio.play();
    } catch (e) {
      console.log(`cannot play the sound file`, e);
    }
  }
}

export const generateSignature = async () => {

  const secret = process.env.REACT_APP_HMAC_SECRET || await getSSMParameter("HMAC_SECRET")// Should not be exposed in production

  const nonce = Date.now().toString(); // Unique nonce for every request

  // Generate HMAC using CryptoJS
  const hmac = CryptoJS.HmacSHA256(nonce, secret);
  const signature = hmac.toString(CryptoJS.enc.Hex);

  return {
    signature,
    nonce
  }
}