// export const LOGIN_USER = "/users/login";
export const LOGIN_USER = "/users/loginWithToken";
export const REGISTER_USER = "/users/register";
export const VERIFY_OTP = "/users/verifyOtp";
export const FORGOT_PASSWORD = "/users/forgotPassword";
export const CHANGE_PASSWORD = "/users/changePassword";
export const USER_SESSION = "/users/session";
export const USER_DETAILS = "/users/profile";
//Settings
export const GET_SETTINGS = "/settings/view";

export const GAME_START = "/games/start";
export const GAME_DETAILS = "/games/details/";
export const GAME_COMPLETE = "/games/complete/";
export const GAME_UPDATE = "/games/update/";
export const GAME_HISTORY_LIST = "/games/history-list";
export const GAME_LEADERBOARD_LIST = "/leaderboard/list"
export const GET_CSRF_TOKEN = "/users/token";

export const USER_LOGOUT = "/users/logout";
export const PURCHASE_ENERGY = "/energy/purchase";
