/* Instruments */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../helper/url_helper";
import { get, post, put, updateCSRFToken, updateToken } from "../../../helper/api_helper";
import { toast } from "react-toastify";

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getUserProfile = createAsyncThunk(
  "login",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      const response = await get(URL.USER_DETAILS);
      if (response?.status === "failure") {
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "login",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      const response = await post("", data);
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const getCsrfToken = createAsyncThunk(
  "getCsrfToken",
  async ({ callBack }, Thunk) => {
    try {
      let response = await get(URL.GET_CSRF_TOKEN)
      console.log("responseresponseresponse", response)
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      updateCSRFToken(response.data.token)
      // localStorage.setItem("authUser", JSON.stringify(response.data))
      // localStorage.setItem("authToken", response.data.authToken)
      callBack && callBack(null, response)
      return response.data
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)

export const telegramLogin = createAsyncThunk(
  "telegramLogin",
  async ({ data, callBack }, Thunk) => {
    console.log("data", data)
    try {
      const response = await post(URL.LOGIN_USER, data);
      if (response?.status === "failure") {
        toast.error(response?.message);
        callBack && callBack(response?.message)
        return Thunk.rejectWithValue({ message: response?.message });
      }
      callBack && callBack(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(typeof error === "string" ? error : error?.message)
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);



export const userSession = createAsyncThunk(
  "userSession",
  async ({ callBack }, Thunk) => {
    try {
      let response = await get(URL.USER_SESSION)
      if (response.status === "failed") {
        callBack && callBack(response?.message, "");
      }
      callBack && callBack("", response);
      return response;
    } catch (error) {
      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error)
    }
  }
)



export const userSessionLogout = createAsyncThunk(
  "userSessionLogout",
  async ({ callBack }, Thunk) => {
    try {
      let response = await post(URL.USER_LOGOUT)
      if (response.status === "failed") {
        toast.error(response?.message);
        callBack && callBack(response?.message, "");
        return Thunk.rejectWithValue(response?.message)
      }
      toast.success(response?.message);
      callBack && callBack("", response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");

      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error)
    }
  }
)