import "./App.css";
import { Providers } from "./lib/providers";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { privateRoutes, routes } from "./pages";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCsrfToken, userSession } from "./lib/store/actions";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <Providers>
      <ToastContainer />
      <BrowserRouter>
        <RoutesList />
      </BrowserRouter>
    </Providers>
  );
}

const RoutesList = () => {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getCsrfToken())
  // }, []);



  useEffect(() => {
    const checkAuth = async () => {
      try {
        await dispatch(userSession({})); // Dispatch the action to check authentication
      } catch (error) {
        console.error("Error checking auth:", error);
      }
    };

    checkAuth();
  }, []);


  return (
    <Routes>
      <Route element={""}>
        <Route index element={<Navigate to="/login" />} />
        {routes.map((data, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={data.component}
            key={index}
          />
        ))}
      </Route>
      <Route element={""}>
        <Route index element={<Navigate to="/game" />} />
        {privateRoutes.map((data, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element=
            {<PrivateRoute>
              {data.component}
            </PrivateRoute>}
            key={index}
          />
        ))}
      </Route>
    </Routes>
  )
}
export default App;
