import React, { useState } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import styles from "./BuyPop.module.scss";

// img
import p1 from "../../../assets/images/current.png";
import diamond from "../../../assets/images/diamond.png";

const BuyPop = ({ buy, setBuy }) => {
  const [qty, setQty] = useState(1);

  const handleQty = (value) => {
    if (value === "min") {
      if (qty > 1) {
        setQty((prev) => prev - 1);
      } else {
        setQty(1);
      }
    } else if (value === "add") {
      if (qty < 15) {
        setQty((prev) => prev + 1); // Increase quantity
      } else {
        setQty(15); // Increase quantity
      }
    }
  };
  const handledBuy = () => {
    setBuy(!buy);
  };
  return (
    <>
      <Modal
        show={buy}
        onHide={handledBuy}
        backdrop="true"
        centered
        className={`${styles.buyPop}`}
        keyboard={false}
      >
        <Modal.Body>
          <Button
            onClick={handledBuy}
            variant="transparent"
            className="border-0 p-0 position-absolute"
            style={{ top: 5, right: 5 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M11.0185 8.49727L16.4832 3.03462C17.1723 2.34585 17.1723 1.20582 16.4832 0.517056C16.1479 0.185781 15.6954 0 15.224 0C14.7525 0 14.3 0.185781 13.9647 0.517056L8.5 5.9797L3.03529 0.517056C2.69995 0.185781 2.24749 0 1.77603 0C1.30457 0 0.852109 0.185781 0.516771 0.517056C-0.172257 1.20582 -0.172257 2.34585 0.516771 3.03462L5.98148 8.49727L0.516771 13.9599C-0.172257 14.6487 -0.172257 15.7887 0.516771 16.4775C0.873166 16.8337 1.3246 17 1.77603 17C2.22746 17 2.67889 16.8337 3.03529 16.4775L8.5 11.0148L13.9647 16.4775C14.3211 16.8337 14.7725 17 15.224 17C15.6754 17 16.1268 16.8337 16.4832 16.4775C17.1723 15.7887 17.1723 14.6487 16.4832 13.9599L11.0185 8.49727V8.49727Z"
                fill="#31B8FF"
              />
            </svg>
          </Button>
          <div className="top pb-3 pt-2 text-center">
            <h5
              className="m-0 text-white d-inline-block pb-1 px-3"
              style={{ borderBottom: "1px solid #356BBF" }}
            >
              Buy
            </h5>
          </div>
          <div className="py-2">
            <div
              className="mx-auto text-center rounded-4 p-3"
              style={{ background: "#232E6C", maxWidth: 229 }}
            >
              <div className="py-1">
                <img src={p1} alt="" className="img-fluid" />
              </div>
              <div
                className={`${styles.quantity} pt-2 pb-3 d-flex align-items-center justify-content-center gap-10`}
              >
                <Button
                  onClick={() => handleQty("min")}
                  className={`${
                    qty > 1 && styles.active
                  } d-flex align-items-center justify-content-center commonBtn border-0`}
                  style={{ minWidth: "unset" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="3"
                    viewBox="0 0 15 3"
                    fill="none"
                  >
                    <rect width="15" height="3" rx="1.5" fill="white" />
                  </svg>
                </Button>
                <input
                  type="text"
                  value={qty}
                  className={`${styles.formControl} form-control text-center fw-bold text-white`}
                />
                <Button
                  onClick={() => handleQty("add")}
                  className={`${
                    qty < 15 && styles.active
                  } d-flex align-items-center justify-content-center commonBtn border-0`}
                  style={{ minWidth: "unset" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <rect
                      y="6.31738"
                      width="16.1092"
                      height="3.15924"
                      rx="1.57962"
                      fill="white"
                    />
                    <rect
                      width="15.7938"
                      height="3.22185"
                      rx="1.61092"
                      transform="matrix(0.00512754 -0.999987 0.999988 0.0049302 6.44098 15.7939)"
                      fill="white"
                    />
                  </svg>
                </Button>
              </div>
              <div
                className="mt-2 d-inline-flex align-items-center px-3 justify-content-center commonBtn text-white fw-bold"
                style={{ minWidth: "unset", boxShadow: "unset" }}
              >
                <span className="icn me-2">
                  <img src={diamond} alt="" className="img-fluid" />
                </span>
                50
              </div>
            </div>
          </div>
          <div className="mt-3 text-center">
            <Button className="d-inline-flex align-itmes-center justify-content-center commonBtn border-0">
              Buy
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuyPop;
