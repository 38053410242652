import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./reducers";
import { middleware } from "./middleware";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
});


export default store;
