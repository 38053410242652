"use client";
/* Core */
import { createSlice } from "@reduxjs/toolkit";

/* Instruments */
import { getSettings } from "./actions";


const initialState = {
  settings: null,
  loading: false,
  error: ""
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export default settingsSlice.reducer;